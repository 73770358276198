<template>
    <div class="app-container">
        <div class="filter-container">
            <el-button class="filter-item" type="primary" size="small" @click="handleCreate">新增</el-button>

            <el-select v-model="listQuery.status" placeholder="状态" style="width: 120px" class="filter-item" size="small" clearable>
                <el-option label="已禁用" :value="0" />
                <el-option label="启用中" :value="1" />
            </el-select>

            <el-button class="filter-item" type="primary" size="small" @click="handleFilter">搜索</el-button>
        </div>

        <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
            <el-table-column label="ID" width="80" align="center">
                <template slot-scope="scope">
                    {{ scope.row.id }}
                </template>
            </el-table-column>

            <el-table-column label="开始时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.start_time }}
                </template>
            </el-table-column>

            <el-table-column label="结束时间" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.end_time }}
                </template>
            </el-table-column>

            <el-table-column label="份额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.stock }}
                </template>
            </el-table-column>

            <el-table-column label="已购买数量" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.pay_count }}
                </template>
            </el-table-column>

            <el-table-column label="每份售价" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.selling_price }}
                </template>
            </el-table-column>

            <el-table-column label="每份赠送余额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.reward_balance }}
                </template>
            </el-table-column>

            <el-table-column label="已赠送总余额" min-width="110" align="center">
                <template slot-scope="scope">
                    {{ scope.row.reward_balance_sum }}
                </template>
            </el-table-column>

            <el-table-column label="状态" min-width="110" align="center">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.status === 1" type="success">启用中</el-tag>
                    <el-tag v-else-if="scope.row.status === 0" type="danger">已禁用</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" align="center" width="200" class-name="small-padding fixed-width">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">编辑</el-button>

                    <el-button type="danger" size="mini" @click="handleChangeStatus(scope.row)" v-if="scope.row.status === 1">禁用</el-button>
                    <el-button type="success" size="mini" @click="handleChangeStatus(scope.row)" v-else>启用</el-button>

                    <el-button type="success" size="mini" @click="handleOrderRecord(scope.row)">购买详情</el-button>
                </template>
            </el-table-column>
        </el-table>

        <div class="pagination-container">
            <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30, 50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>

        <!-- 详情 -->
        <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="50%" :close-on-click-modal="false">
            <el-form ref="form" :rules="rules" :model="form" label-position="left" label-width="120px">
                <el-form-item label="开始时间" prop="start_time">
                    <el-date-picker v-model="form.start_time" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>

                <el-form-item label="结束时间" prop="end_time">
                    <el-date-picker v-model="form.end_time" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                </el-form-item>

                <el-form-item label="份额" prop="stock">
                    <el-input-number v-model="form.stock" :min="0" :precision="0"></el-input-number>
                </el-form-item>

                <el-form-item label="每份售价" prop="selling_price">
                    <el-input-number v-model="form.selling_price" :min="0" :precision="2"></el-input-number>
                </el-form-item>

                <el-form-item label="每份赠送余额" prop="reward_balance">
                    <el-input-number v-model="form.reward_balance" :min="0" :precision="2"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
            </div>
        </el-dialog>
        <!-- 详情 -->
    </div>
</template>

<script>
    import request from "@/utils/request";
    import { mapGetters } from "vuex";

    export default {
        data() {
            return {
                list: null,
                total: null,
                listLoading: true,
                listQuery: {
                    page: 1,
                    limit: 10,
                    status: '',
                },
                dialogFormVisible: false,
                dialogStatus: '',
                textMap: {
                    update: '修改',
                    create: '新增',
                },
                btnLoading: false,
                form:{
                    id: '',
                    start_time: '',
                    end_time: '',
                    stock: 0,
                    selling_price: 0,
                    reward_balance: 0,
                },
                rules: {
                    start_time: [{ required: true, message: '开始时间不能为空', trigger: 'change' }],
                    end_time: [{ required: true, message: '结束时间不能为空', trigger: 'change' }],
                    stock: [{ required: true, message: '份额不能为空', trigger: 'change' }],
                    selling_price: [{ required: true, message: '每份售价不能为空', trigger: 'change' }],
                    reward_balance: [{ required: true, message: '每份赠送余额不能为空', trigger: 'change' }],
                },
            };
        },
        created() {
            this.getList();
        },
        computed: {
            ...mapGetters(["schools", "school_id","user"]),
        },
        methods: {
            getList() {
                this.listLoading = true;
                request({
                    url: "/api/backend/agriculturalBankActivities/list",
                    method: "get",
                    params: this.listQuery
                }).then(response => {
                    this.list = response.data.data;
                    this.total = response.data.total;
                    this.listLoading = false;
                });
            },
            handleFilter() {
                this.listQuery.page = 1;
                this.getList();
            },
            handleSizeChange(val) {
                this.listQuery.limit = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.listQuery.page = val;
                this.getList();
            },
            resetForm() {
                this.form = {
                    id: '',
                    start_time: '',
                    end_time: '',
                    stock: 0,
                    selling_price: 0,
                    reward_balance: 0,
                }
                this.btnLoading = false
            },
            handleCreate() {
                this.resetForm()
                this.dialogStatus = 'create'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            handleUpdate(row) {
                this.resetForm()
                this.form = Object.assign({}, row)
                this.dialogStatus = 'update'
                this.dialogFormVisible = true
                this.$nextTick(() => {
                    this.$refs['form'].clearValidate() //清除校验结果
                })
            },
            saveData() {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.btnLoading = true
                        request({
                            url: '/api/backend/agriculturalBankActivities/save',
                            method: 'post',
                            data: this.form
                        }).then(() => {
                            this.btnLoading = false
                            this.dialogFormVisible = false
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getList();
                        }).catch(() => {
                            this.btnLoading = false
                        })
                    }
                })
            },
            handleChangeStatus(row) {
                this.listLoading = true;
                request({
                    url: '/api/backend/agriculturalBankActivities/changeStatus',
                    method: 'post',
                    data: {
                        id: row.id,
                    },
                }).then(() => {
                    this.$message({
                        type: 'success',
                        message: '操作成功'
                    });
                    this.getList();
                });
            },
            handleOrderRecord(row) {
                this.$router.push(`/activities/orderRecord?activities_id=${row.id}`);
            },
        }
    };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
    .user-avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
    }
</style>
